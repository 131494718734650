import restaurant from "./images/savita.jpg";
import biserica from "./images/biserica.jpg";
import img_card from "./images/alex2.png";
import imgheadermb from "./images/lion.jpg";
import imgheader from "./images/lion.jpg";
import imgheadermiini from "./images/lion2.jpg";
import imgheadermiinimb from "./images/simba2.jpg";
import logo from './images/lion2.png'



const data = {
    introData: [{
        copilul: "Alex",
        familia: "fam. Vasilița",
        logo: logo,
        tata: "Alexandru",
        mama: "Tatiana",
        data: "21 Iulie 2023",
        data_confirmare: "1 iulie 2023",
        savedata: "~ Vă invită! ~",
        imgdesktop: imgheader,
        imgmobil: imgheadermb,
        email: "tanny.diacenco@gmail.com", 
       tel: "+491786153471",
       phone: "tel:+491786153471",
       viber: "viber://chat?number=%2B491786153471",
       whatsapp: "https://wa.me/+491786153471",
       messenger: "https://www.messenger.com/t/100076501699838",
    }],
    cardData: [
        {
            id: 1,
            img: restaurant,
            title: "Petrecerea Evenimentului",
            localul: "Restaurantul",
            name: "Savita Banchet Hall",
            data: "21 iulie 2023, vineri, ora 18:00",
            adress: "s. Speia, r. Anenii Noi",
            harta: "https://goo.gl/maps/iidZM4EJyFb97SBT9",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10880.718167868454!2d29.2898821!3d47.0170807!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2cb6530a1363ea3e!2sSavita!5e0!3m2!1sro!2s!4v1670931903991!5m2!1sro!2s" 
        },

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "Salutare",
          
            message: "Dacă ați ajuns aici înseamnă că ținem la voi și ne-am bucura enorm dacă veți fi alături de noi într-o zi atât de specială!",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: "",
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua in care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }
    ],
    blogDataOmSuflet: [
        {

            img: '',
            title: "Oameni de suflet!",
            message: "Alături de care vom scrie povestea noastră.",
            nasii: "",
            nasii_nume: "",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title1: "Cu mult drag și încântare ",
            title2: "Te invit la o petrecere tare!",
            title3: "Cu muzică și voie bună",
            message: "Ne vom distra clar de minune!",  
        }
    ],

}

export default data;